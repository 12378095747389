import React, { useState, useEffect } from 'react'
import Component from './index'

export default function Editable ({ data }) {
  const [slides, setSlides] = useState(data.slides)
  useEffect(async () => {
    setSlides(
      await Promise.all(
        data.slides.map(slide =>
          new Promise((resolve) => {
            const img = new Image()
            img.onload = function () {
              resolve({ ...slide, aspectRatio: this.width / this.height })
            }
            img.src = slide.bigImage
          })
        )
      )
    )
  }, [data.slides])
  return <Component data={{ ...data, slides }} />
}
